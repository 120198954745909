// components/ProductCard.tsx
"use client";

import React, { FC, useState, useCallback, useMemo } from "react";
import useSWR from 'swr';
import Prices from "./Prices";
import { Product } from "@/interfaces/product.interface";
import { useCartContext } from "@/context/cart-provider";
import toast from "react-hot-toast";
import { useRouter } from "next/navigation";
import NcImage from "@/shared/NcImage/NcImage";
import CartIcon from "./icons/cart/cart";
import NotifyAddTocart from "./NotifyAddTocart";
import Link from "next/link";
import ModalQuickView from "./ModalQuickView";
import { COMISSION_PERCENTAGE, STORE_PERCENTAGE, VAT_PERCENTAGE } from "@/constants";

const fetcher = (url: string) => fetch(url).then((res) => res.json());

export interface ProductCardProps {
  className?: string;
  data?: any; //Product;
  isLiked?: boolean;
}

const ProductCard: FC<ProductCardProps> = ({
  className = "",
  data,
  isLiked
}) => {
  const [variantActive, setVariantActive] = useState(0);
  const [showModalQuickView, setShowModalQuickView] = useState(false);
  const { dispatch } = useCartContext();
  const router = useRouter();

  // Obtener la tasa de cambio
  const { data: exchangeRateData, error } = useSWR('/api/exchange-rate', fetcher, {
    refreshInterval: 86400000 // Actualizar cada hora
  });

  const calculateFinalPrice = useCallback((price: number): number => {
    const commission = COMISSION_PERCENTAGE; // 3.5%
    const additionalPercentage = STORE_PERCENTAGE; // 20%
    const vat = VAT_PERCENTAGE; // 16% IVA (opcional)

    const commissionAmount = price * commission;
    const subtotalWithCommission = price + commissionAmount;
    const additionalAmount = subtotalWithCommission * additionalPercentage;
    const subtotalWithAdditional = subtotalWithCommission + additionalAmount;

    // Si se requiere calcular el IVA:
    const vatAmount = subtotalWithAdditional * vat;
    const finalPrice = subtotalWithAdditional + vatAmount;

    return finalPrice;
  }, []);


  const originalPrice = useMemo(() => calculateFinalPrice(parseFloat(data?.precios?.precio_descuento!) || 0), [data?.precios?.precio_descuento, calculateFinalPrice]);


  const convertedPrice = useMemo(() => {
    // Calcular el precio final basado en la tasa de cambio si está disponible
    let finalPrice = originalPrice;

    if (exchangeRateData && exchangeRateData.rate) {
      finalPrice = originalPrice * exchangeRateData.rate;
    }

    // Retornar el precio final sin formatear, como número
    return finalPrice;
  }, [originalPrice, exchangeRateData]);


  const addToCart = (data?: Product) => {
    if (!data) return;

    dispatch({
      type: "ADD_ITEM",
      payload: {
        productId: data.producto_id,
        title: data?.titulo?.split('/')[0].trim().substring(0, 30),
        image: data.img_portada,
        price: convertedPrice.toFixed(2),
        quantitySelected: 1,
        inStock: data.total_existencia
      }
    });

    toast.custom(
      (t) => (
        <NotifyAddTocart
          productImage={data.img_portada}
          quantitySelected={1}
          show={t.visible}
          name={data?.titulo?.split('/')[0].trim().substring(0, 30)}
          price={new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(convertedPrice)}
          variantActive={1}
          onClose={() => {
            toast.dismiss("nc-product-notify");
          }}
        />
      ),
      { position: "top-right", id: "nc-product-notify", duration: 3000 }
    );
  };

  return (
    <>
      <div className={`nc-ProductCard relative flex flex-col bg-transparent ${className} cursor-pointer`}>
        <Link href={{
          pathname: "/product-detail",
          query: { productId: data!.producto_id.toString() }
        }} className="block">
          <NcImage
            containerClassName="flex aspect-w-11 aspect-h-12 w-full h-0"
            src={data?.img_portada || ''}
            className="object-cover w-full h-full drop-shadow-xl"
            fill
            sizes="(max-width: 640px) 100vw, (max-width: 1200px) 50vw, 40vw"
            alt="product"
          />
        </Link>

        <div className="space-y-4 px-2.5 pt-5 pb-2.5">
          <div>
            <h2 className="nc-ProductCard__title text-base font-semibold transition-colors">
              {data?.titulo?.split('/')[0].trim().substring(0, 30)}
            </h2>
          </div>

          <div className="flex justify-between items-end ">
            <div className="flex items-center mb-0.5">
              <Prices price={new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(convertedPrice)} />
              <div
                className='w-5 h-5 pb-[1px] ml-4'
                onClick={() => addToCart(data)}
              >
                <CartIcon width={25} height={25} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalQuickView
        show={showModalQuickView}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
      />
    </>
  );
};

export default ProductCard;